import MyIP from './component/myip/Index'
import './App.css';

function App() {
  return (
    <div className="container">
       <MyIP />
    </div>
  );
}

export default App;
