import React from "react";
import IPinfo from "./IPinfo";
import Freeip from "./Freeip";
import IPgeo from "./IPgeo";
import IPapi from "./IPapi";
//import IndexTitle from "./IndexTitle";   //暂时不显示

const Index = () => {
  return (
    <>
      {/* <div className="row">
        <IndexTitle />
      </div> */}
      <div className="row my-2">
        <div className="col-lg-3 my-1">
          <Freeip />
        </div>
        <div className="col-lg-3 my-1">
          <IPapi />
        </div>
        <div className="col-lg-3 my-1">
          <IPgeo />
        </div>
        <div className="col-lg-3 my-1">
          <IPinfo />
        </div>
      </div>
    </>
  );
};

export default Index;
